<template>
  <div class="box">
    <div class="mask">
      <!-- <img id="qrImg" src="" alt=""> -->
      <div id="qrcode" ref="qrCodeUrl"></div>
    </div>
    <div class="top">
      <div class="title">查看人员信息</div>
    </div>
    <div class="timePicker">
      <el-date-picker
        v-model="timeDate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd">
      </el-date-picker>
      <div class="findBtn">
        <el-button type="primary" @click="find()">查询</el-button>

      </div>
    </div>

    <div class="tableData">
      <el-table
        :data="tableData"
        stripe
        :header-cell-style="{
          background: '#189f9240',
          textAlign: 'center',
          color: '#333333',
          fontWeight: 'normal',
        }"
        :cell-style="{
          textAlign: 'center',
        }"
        style="width: 100%">
        <el-table-column width="70" prop="idx" label="序号"></el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          width="100">
        </el-table-column>
        <el-table-column
          prop="number"
          label="编号"
          width="100">
        </el-table-column>
        <el-table-column
          prop="unit"
          label="体检单位">
        </el-table-column>
        <el-table-column
          prop="dataTime"
          label="体检日期"
          width="190">
        </el-table-column>
        <el-table-column
          prop="category"
          label="人员类别"
          width="120">
        </el-table-column>
        <el-table-column
          prop="timeout"
          label="健康证是否过期"
          width="130"> 
          <template slot-scope="scope">
            <span v-if="scope.row.timeout">已过期</span>
            <span v-else>未过期</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="操作" width="150">
          <template slot-scope="scope">
            <div class="btnBox">
              <el-button size="small" type="danger" @click="deleteData(scope.row.id, scope.row.name)">删除</el-button>
              <el-button size="small" type="primary" @click="downData(scope.row.id, scope.row.name)">下载</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[50, 100, 150, 200, 250]"
          :page-size="10" 
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    
  </div>
</template>

<script>
import { rental, deleteID } from '@/api/index.js'
import QRCode from 'qrcodejs2'
export default {
  components: {},
  props: {},
  data() {
    return {
      timeDate: '',
      tableData: [],
      total: 10,
      currentPage: 1,
      pageSize: 50,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.find()
  },
  methods: {
    find() {
      console.log('timeDate', this.timeDate)
      let obj={}
      if (this.timeDate && this.timeDate.length!=0) {
        obj = {
          startTime: this.timeDate[0],
          endTime: this.timeDate[1],
        }
      }
      obj.pageSize = this.pageSize
      obj.pageNum = this.currentPage
      rental(obj).then(res => {
        this.tableData = res.data
        this.tableData.forEach((item,index) => {
          item.idx = (this.currentPage-1)*this.pageSize + (index+1)
        })
        this.total = res.total
        // console.log('sub', res.data)
      })
    },
    deleteData(id,name) {
      deleteID(id).then(res => {
        this.find()
        this.$notify({
          title: '删除成功',
          message: `成功删除${name}信息`,
          type: 'success'
        });
        console.log('res', res)
      })
    },
    downData(id, name) {
      new QRCode(this.$refs.qrCodeUrl, {
        // text: `http://192.168.1.57:8089/index/index/user?id=${id}`, // 需要转换为二维码的内容
        // text: `http://47.116.28.55/index/index/user?id=${id}`, // 需要转换为二维码的内容
        text: `https://hbldqy.cn/index/index/user?id=${id}`, // 需要转换为二维码的内容
        width: 297,
        height: 297,
        colorDark: '#000000',
        colorLight: '#ffffff00',
        correctLevel: QRCode.CorrectLevel.L
      })
      // console.log(`http://192.168.1.57:8089/index/index/user?id=${id}`)
      const qrcode = document.querySelector('#qrcode')
      const canvas = qrcode.querySelector('canvas')
      const imgurl = canvas.toDataURL('image/png')
      // console.log('imgurl', imgurl)
      // const a = document.createElement('a')
      // a.href = imgurl
      // a.download = '二维码' // 图片名称
      // a.click()
      var base64 = imgurl; // imgSrc 就是base64哈
      var byteCharacters = atob(
        base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      );
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: undefined,
      });
      var aLink = document.createElement("a");
      aLink.download = `${name}.png`; //这里写保存时的图片名称
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
      qrcode.innerHTML=''
    },
    /* 分页 */
    handleSizeChange(val) {
      this.pageSize = val
      this.find()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.find()
    },
  }
}
</script>
<style lang="scss" scoped>
.mask {
  display: none;
}
.box {
  min-height: 90vh;
  width: 98%;
  margin: 1%;
  padding: 1% 2%;
  background-color: #fff;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  .title {
    padding-left: 10px;
    border-left: 4px solid #189F92;
  }
}
.el-button--primary {
    color: #FFF;
    background-color: #189F92;
    border-color: #189F92;
}

.findBtn {
  margin: 0 50px;
}
.timePicker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
.btnBox {

}
.fy {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 20px 0;
}
</style>
